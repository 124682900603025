<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                RENTAL INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step> -->

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step step="3">
                COVERAGE
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0 mb-16">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="next()"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Property Info
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Your Property
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  class="text-center"
                >
                  <p>
                    We’ll need to ask a few questions in order to get you the
                    best possible coverage options.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <p>Policy Start Date</p>
                  <v-dialog
                    ref="startDateDialog"
                    v-model="startDateDialog"
                    :return-value.sync="policy.startDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <v-text-field
                          v-model="policy.startDate"
                          outlined
                          label="Policy Start Date"
                          :error-messages="errors"
                          readonly
                          v-bind="attrs"
                          prepend-icon="mdi-calendar"
                          v-on="on"
                          @click="
                            clickedFieldGlobal(
                              'propertyInfo poliStartDate',
                              52,
                              'Renters Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="policy.startDate"
                      scrollable
                      :min="minDate"
                      :max="maxDate"
                      color="secondary"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          startDateDialog = false;
                          clickedFieldGlobal(
                            'propertyInfo poliStartDateCancelBtn',
                            53,
                            'Renters Survey v1'
                          );
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.startDateDialog.save(policy.startDate);
                          clickedFieldGlobal(
                            'propertyInfo poliStartDateOkBtn',
                            54,
                            'Renters Survey v1'
                          );
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="SSN"
                    rules="required|min:11|max:11"
                  >
                    <v-text-field
                      v-model="actualSSN"
                      v-mask="'###-##-####'"
                      outlined
                      label="Social Security Number"
                      color="primary"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <p>
                    Construction Type |
                    <a
                      class="primary--text text-decoration-underline"
                      @click="
                        constructionTypeDialog = true;
                        clickedFieldGlobal(
                          'propertyInfo constructTypeDialogView',
                          55,
                          'Renters Survey v1'
                        );
                      "
                    >View Construction Types</a>
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Construction Type"
                    rules="required"
                  >
                    <v-select
                      v-model="property.constructionType"
                      outlined
                      label="Contruction Type"
                      color="primary"
                      :items="constructionTypes"
                      item-text="txt"
                      item-value="value"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'propertyInfo constructType',
                          56,
                          'Renters Survey v1'
                        )
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-dialog
                v-model="constructionTypeDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h5 pa-12">
                      Construction types <br /><br />

                      a. Masonry, Brick or Stone: The structure itself is built
                      using masonry, brick or stone. The exterior finish
                      materials may include: brick, paint, siding (including
                      HardiBoard, HardiPlank, etc.), stone and stucco.
                      <br /><br />

                      b. Frame: The structure itself is built using wood or
                      steel framing. The exterior finish materials may include:
                      clapboard or other siding and shakes, metal, vinyl and
                      wood. Note that framing may be visible in unfinished
                      basements or garages. <br /><br />

                      c. Masonry Veneer over Frame: The structure itself is
                      built using wood or steel framing. The exterior finish
                      materials may include: thin veneers of brick, stone and
                      stucco. Note that framing may be visible in unfinished
                      basements or garages. <br /><br />
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      text
                      x-large
                      @click="
                        constructionTypeDialog = false;
                        clickedFieldGlobal(
                          'propertyInfo constructTypeDialogCloseBtn',
                          57,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row>
                <v-col>
                  <div
                    v-for="(question, index) in questions"
                    :key="question.id"
                    :index="index"
                  >
                    <div v-if="!question.dependentRule">
                      <p>
                        {{ question.question }}
                      </p>
                      <v-radio-group
                        v-if="question.answers.type === 'Y/N'"
                        v-model="answers[index]"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[0].value"
                          :label="question.answers.values[0].txt"
                          @click="
                            setAnswer(
                              question.qCode,
                              question.answers.values[0].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - yes`,
                              58
                            );
                          "
                        >
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[1].value"
                          :label="question.answers.values[1].txt"
                          @click="
                            setAnswer(
                              question.qCode,
                              question.answers.values[1].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - no`,
                              58
                            );
                          "
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      v-if="
                        question.dependentRule &&
                          question.dependentRule.action === 'DISPLAY' &&
                          searchList(question) &&
                          question.answers.type === 'Y/N'
                      "
                    >
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="answers[index]"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[0].value"
                          :label="question.answers.values[0].txt"
                          @click="
                            setAnswer(
                              question.qCode,
                              question.answers.values[0].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - yes`,
                              59
                            );
                          "
                        >
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[1].value"
                          :label="question.answers.values[1].txt"
                          @click="
                            setAnswer(
                              question.qCode,
                              question.answers.values[1].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - no`,
                              59
                            );
                          "
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      v-if="
                        question.dependentRule &&
                          question.dependentRule.action === 'DISPLAY' &&
                          searchList(question) &&
                          question.answers.type === 'integer'
                      "
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Amount"
                        rules="required|numeric"
                      >
                        <p>{{ question.question }}</p>
                        <v-text-field
                          v-model.number="answers[index]"
                          v-mask="'#########'"
                          outlined
                          color="primary"
                          :error-messages="errors"
                          @blur="
                            setAnswer(
                              question.qCode,
                              answers[index],
                              question.id
                            )
                          "
                          @click="
                            clickedFieldGlobal(
                              'propertyInfo lossQty',
                              60,
                              'Renters Survey v1'
                            )
                          "
                        ></v-text-field>
                      </validation-provider>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Liability')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`LIABILITYLOSS${n}`"
                            :n="n"
                            :min="minLossDate"
                            :max="maxLossDate"
                            type="Liability"
                            location-ref="LOC1"
                            loss-cause="Liability"
                            :loss-codes="lossCodes"
                            :click-field-values="lossComponents.liabilityLosses.clickFieldValues"
                            :sequence-numbers="lossComponents.liabilityLosses.sequenceNumbers"
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Water')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`WATERLOSS${n}`"
                            :n="n"
                            :min="minLossDate"
                            :max="maxLossDate"
                            type="Water"
                            location-ref="LOC1"
                            loss-cause="Water"
                            :loss-codes="lossCodes"
                            :click-field-values="lossComponents.waterLosses.clickFieldValues"
                            :sequence-numbers="lossComponents.waterLosses.sequenceNumbers"
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Mold')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`MOLDLOSS${n}`"
                            :n="n"
                            :min="minLossDate"
                            :max="maxLossDate"
                            type="Mold"
                            location-ref="LOC1"
                            loss-cause="Mold"
                            :loss-codes="lossCodes"
                            :click-field-values="lossComponents.moldLosses.clickFieldValues"
                            :sequence-numbers="lossComponents.moldLosses.sequenceNumbers"
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Weather')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`WEATHERLOSS${n}`"
                            :n="n"
                            :min="minLossDate"
                            :max="maxLossDate"
                            type="Weather"
                            location-ref="LOC1"
                            loss-cause="Weather"
                            :loss-codes="lossCodes"
                            :click-field-values="lossComponents.weatherLosses.clickFieldValues"
                            :sequence-numbers="lossComponents.weatherLosses.sequenceNumbers"
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                      <div
                        v-if="
                          answers[index] > 0 &&
                            question.question.includes('Other')
                        "
                      >
                        <div
                          v-for="(n, i) in answers[index]"
                          :key="i"
                        >
                          <loss-component
                            :id="`OTHERLOSS${n}`"
                            :n="n"
                            :min="minLossDate"
                            :max="maxLossDate"
                            type="Other"
                            location-ref="LOC1"
                            loss-cause="Other"
                            :loss-codes="lossCodes"
                            :click-field-values="lossComponents.otherLosses.clickFieldValues"
                            :sequence-numbers="lossComponents.otherLosses.sequenceNumbers"
                            :survey-type="surveyType"
                            @record-loss="recordLoss"
                          ></loss-component>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Discounts
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="11"
                  md="9"
                >
                  <div
                    v-for="(question, index) in discountQuestions"
                    :key="question.id"
                    :index="index"
                  >
                    <div v-if="question.answers.type === 'custom'">
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="customDiscount"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          label="YES"
                          value="YES"
                          @click="
                            setCustomDiscountAnswer(
                              question.jCode,
                              'YES',
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - yes`,
                              76
                            );
                          "
                        >
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          text
                          label="NO"
                          value="NO"
                          outlined
                          @click="
                            setCustomDiscountAnswer(
                              question.jCode,
                              'NO',
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - no`,
                              76
                            );
                          "
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <!-- Question with ID '4255' -->
                    <div v-if="question.id === '4255'">
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="discountAnswersList[index]"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          :value="question.answers.values[0].value"
                          label="YES"
                          color="blanketbutton"
                          text
                          outlined
                          @click="setDiscountAnswer(
                            question.jCode[0],
                            question.answers.values[0].value,
                            question.id
                          )"
                        >
                        </v-radio>

                        <v-radio
                          :value="question.answers.values[1].value"
                          :label="'NO'"
                          color="blanketbutton"
                          text
                          outlined
                          @click="setDiscountAnswer(
                            question.jCode[0],
                            question.answers.values[1].value,
                            question.id
                          )"
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>

                    <!-- Question with ID '4305' -->
                    <div v-if="question.id === '4305'">
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="discountAnswersList[index]"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          :value="question.answers.values[0].value"
                          :label="'YES'"
                          color="blanketbutton"
                          text
                          outlined
                          @click="setDiscountAnswer(
                            question.jCode[0],
                            question.answers.values[0].value,
                            question.id
                          )"
                        >
                          {{ question.answers.values[0].txt }}
                        </v-radio>

                        <v-radio
                          :value="question.answers.values[1].value"
                          :label="'NO'"
                          color="blanketbutton"
                          text
                          outlined
                          @click="setDiscountAnswer(
                            question.jCode[0],
                            question.answers.values[1].value,
                            question.id
                          )"
                        >
                          {{ question.answers.values[1].txt }}
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="question.id === '2000'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="discountAnswersList[index]"
                          :rules="[v => !!v || 'This field is required']"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          return-object
                          @change="
                            sprinklerCd = discountAnswersList[index].value
                          "
                          @click="
                            clickedFieldGlobal(
                              'propertyInfo questionId2000.above',
                              77,
                              'Renters Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div
                      v-if="question.answers.type === 'select' && !selectExcludedIds.includes(question.id)"
                    >
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="discountAnswersList[index]"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          return-object
                          @change="
                            setDiscountAnswer(
                              question.jCode[0],
                              discountAnswersList[index].value,
                              question.id
                            )
                          "
                          @click="
                            clickedFieldGlobal(
                              'propertyInfo questionTypeSelect',
                              78,
                              'Renters Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div
                      v-if="question.id === '4965'"
                    >
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="maritalStatus"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          @click="
                            clickedFieldGlobal(
                              'propertyInfo questionTypeSelect',
                              78,
                              'Renters Survey v1'
                            )
                          "
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div
                      v-if="
                        question.answers.type === 'Y/N' &&
                          !exceptionIds.includes(question.id)
                      "
                    >
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="discountAnswersList[index]"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[0].value"
                          :label="'YES'"
                          @click="
                            setDiscountAnswer(
                              question.jCode[0],
                              question.answers.values[0].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - yes`,
                              79
                            );
                          "
                        >
                          {{ question.answers.values[0].txt }}
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[1].value"
                          :label="'NO'"
                          @click="
                            setDiscountAnswer(
                              question.jCode[0],
                              question.answers.values[1].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - no`,
                              79
                            );
                          "
                        >
                          {{ question.answers.values[1].txt }}
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="question.id === '9099'">
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="answers[index]"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[0].value"
                          label="YES"
                          @click="
                            setAnswer(
                              question.qCode,
                              question.answers.values[0].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - yes`,
                              80
                            );
                          "
                        >
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          text
                          outlined
                          :value="question.answers.values[1].value"
                          label="NO"
                          @click="
                            setAnswer(
                              question.qCode,
                              question.answers.values[1].value,
                              question.id
                            );
                            clickedField(
                              `coverageQuestions ${index} - no`,
                              80
                            );
                          "
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="question.id === '1205'">
                      <p>{{ question.question }}</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="above"
                        rules="required"
                      >
                        <v-select
                          v-model="roofType"
                          :rules="[v => !!v || 'This field is required']"
                          :items="question.answers.values"
                          :error-messages="errors"
                          outlined
                          item-value="value"
                          item-text="txt"
                          return-object
                        >
                        </v-select>
                      </validation-provider>
                    </div>
                    <div v-if="question.id === '4563'">
                      <p>{{ question.question }}</p>
                      <v-radio-group
                        v-model="stormShutters"
                        class="mb-4"
                        :rules="[v => !!v || 'This field is required']"
                      >
                        <v-radio
                          color="blanketbutton"
                          label="YES"
                          text
                          outlined
                          value="B"
                          @click="
                            clickedField(
                              `coverageQuestions ${index} - yes`,
                              81
                            )
                          "
                        >
                        </v-radio>
                        <v-radio
                          color="blanketbutton"
                          label="NO"
                          text
                          outlined
                          value="NO"
                          @click="
                            clickedField(
                              `coverageQuestions ${index} - no`,
                              81
                            )
                          "
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="loading"
                class="mt-12"
              >
                <v-col class="d-flex justify-center">
                  <ellipse-progress
                    :loading="loading"
                    :size="180"
                    img-src="src/assets/images/renters-icon.png"
                    thickness="5"
                    empty-thickness="3"
                    line-mode="out 5"
                    legend="false"
                    animation="rs 700 1000"
                    font-size="1.5rem"
                    color="#00A1B7"
                    empty-color-fill="transparent"
                    filename="renters-icon.png"
                  >
                  </ellipse-progress>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      :disabled="invalid || loading"
                      @click="
                        next();
                        clickedFieldGlobal(
                          'propertyInfo next',
                          82,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Next
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="errorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this time due to the following error:
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-italic">
              {{ error }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold mr-0"
                to="/renters/info"
                @click="clearError"
              >
                Restart Application
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/"
                @click="clearError"
              >
                Return to Home
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-row
          no-gutters
          class="d-flex justify-center font-weight-bold text-h5"
        >
          <v-col class="d-flex justify-center font-weight-bold text-center">
            Please contact us at 1-(888)-333-3145 for support with your application.
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import numeral from 'numeral'
  import lossComponent from '../components/Stillwater/LossComponent'
  import EllipseProgress from '../components/EllipseProgress.vue'
  export default {
    components: {
      lossComponent,
      EllipseProgress
    },
    data () {
      return {
        actualSSN: null,
        maxLossDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        minLossDate: moment()
          .subtract(75, 'years')
          .format('YYYY-MM-DD'),
        customDiscount: null,
        selectExcludedIds: ['2000', '1205', '4255', '4305', '4965'],
        maritalStatus: null,
        errorDialog: false,
        roofType: '',
        stormShutters: '',
        exceptionIds: ['9120', '9099', '363', '4563'],
        sprinklerCd: '',
        liabilityLosses: {
          dates: [],
          amounts: [],
          lossCauseCd: 'Liability',
          claimStatusCodes: []
        },
        fireExtinguisherInd: '',
        lossAmounts: [],
        losses: [],
        recordedLosses: [],
        question9099: 'NO',
        discountAnswersList: [],
        discountAnswers: [],
        customDiscountAnswers: [],
        answers: [],
        answerList: [],
        custom: '',
        newCoverageAmount: '',
        coverageIncreaseDialog: false,
        constructionTypeDialog: false,
        constructionTypes: [
          { txt: 'Masonry, Brick or Stone', value: 'B' },
          { txt: 'Frame', value: 'F' },
          { txt: 'Brick stone, or masonry veneer', value: 'V' }
        ],
        property: {
          constructionType: ''
        },
        policyDialog: false,
        existingPolicy: false,
        existingPolicyNumber: null,
        startDateDialog: false,
        minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        maxDate: moment()
          .add(91, 'days')
          .format('YYYY-MM-DD'),
        policy: {
          startDate: ''
        },
        lossComponents: {
          liabilityLosses: {
            clickFieldValues: ['propertyInfo liabilityLoss.dateOfLoss', 'propertyInfo liabilityLoss.amountOfPaid', 'propertyInfo liabilityLoss.claimStatus'],
            sequenceNumbers: [61, 62, 63]
          },
          waterLosses: {
            clickFieldValues: ['propertyInfo waterLoss.dateOfLoss', 'propertyInfo waterLoss.amountOfPaid', 'propertyInfo waterLoss.claimStatus'],
            sequenceNumbers: [64, 65, 66]
          },
          moldLosses: {
            clickFieldValues: ['propertyInfo moldLoss.dateOfLoss', 'propertyInfo moldLoss.amountOfPaid', 'propertyInfo moldLoss.claimStatus'],
            sequenceNumbers: [67, 68, 69]
          },
          weatherLosses: {
            clickFieldValues: ['propertyInfo weatherLoss.dateOfLoss', 'propertyInfo weatherLoss.amountOfPaid', 'propertyInfo weatherLoss.claimStatus'],
            sequenceNumbers: [70, 71, 72]
          },
          otherLosses: {
            clickFieldValues: ['propertyInfo otherLoss.dateOfLoss', 'propertyInfo otherLoss.amountOfPaid', 'propertyInfo otherLoss.claimStatus'],
            sequenceNumbers: [73, 74, 75]
          },
        },
        surveyType: 'Renters Survey v1'
      }
    },
    computed: {
      formattedSSN () {
        return this.actualSSN.replace(/-/g, '')
      },
      error () {
        return this.$store.getters.error
      },
      construction () {
        let construction = {
          constructionCd: this.property.constructionType
        }
        if (this.roofType) {
          Object.assign(construction, {
            roofGeometryTypeCd: this.roofType.value
          })
        }
        if (this.stormShutters) {
          Object.assign(construction, { stormShuttersCd: this.stormShutters })
        }
        return construction
      },
      questionAnswers () {
        return this.answerList.filter(answer => answer.qCode !== '')
      },
      lossCodes () {
        return this.$store.getters.getStillwaterLossCodes
      },
      amt () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]
      },
      renter () {
        return this.$store.getters.getRenter
      },
      questions () {
        return this.$store.getters.getRentalSurveyQuestions
      },
      discountQuestions () {
        return this.$store.getters.getRentalDiscountQuestions
      },
      loading () {
        return this.$store.getters.getloading
      },
      quote () {
        return this.$store.getters.getRentalQuote
      },
      deductible () {
        let deductible = this.quote.ACORD.InsuranceSvcRs[0]
          .HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].Coverage[0]
          .Deductible[0].FormatInteger[0]
        return parseInt(deductible.replace(/,/g, ''))
      },
      quoteDeductible () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      customQuoteDeductible () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
            .FormatInteger[0]
        ).format('0.00')
      },
      customQuotePPR () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      customQuotePremium () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0.00')
      },
      customQuoteLiability () {
        return numeral(
          this.customQuote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[2].Limit[0].FormatInteger[0]
        ).format('0,0.00')
      },
      contentsCoverage () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].HomeLineBusiness[0].Dwell[0].TotalInsurableReplCostAmt[0].Amt[0]
      },
      bldgProtection () {
        let protectionDeviceBurglarCd = this.discountAnswers.find(
          answer => answer.jCode === 'protectionDeviceBurglarCd'
        )
        let protectionDeviceFireCd = this.discountAnswers.find(
          answer => answer.jCode === 'protectionDeviceFireCd'
        )
        return {
          fireExtinguisherInd: this.customDiscountAnswers[0].answer
            ? this.customDiscountAnswers[0].answer
            : 0,
          protectionDeviceSmokeCd: this.customDiscountAnswers[1].answer
            ? this.customDiscountAnswers[1].answer
            : '',
          doorLockCd: this.customDiscountAnswers[2].answer
            ? this.customDiscountAnswers[2].answer
            : '',
          protectionDeviceBurglarCd: protectionDeviceBurglarCd
            ? protectionDeviceBurglarCd.answer
            : '',
          protectionDeviceFireCd: protectionDeviceFireCd
            ? protectionDeviceFireCd.answer
            : '',
          protectionDeviceSprinklerCd: this.sprinklerCd ? this.sprinklerCd : ''
        }
      }
    },
    watch: {
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      }
    },
    created () {
      console.log(this.quote)
      console.table(this.questions)
      console.log('discount answers', this.discountAnswers)
    },
    mounted () {
      console.log('disc qs ', this.discountQuestions)
      console.log('loss codes ', this.lossCodes)
    },
    methods: {
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      isSelectQuestion (question) {
        return (
          question.answers.type === 'select' &&
          question.id !== '2000' &&
          question.id !== '1205'
        )
      },
      recordLoss (loss) {
        // check if loss is already in recordedLosses array
        let l = this.recordedLosses.find(l => l.id === loss.id)
        if (l) {
          this.recordedLosses.splice(this.recordedLosses.indexOf(l), 1)
          this.recordedLosses.push(loss)
        } else {
          this.recordedLosses.push(loss)
        }
        console.log('RECORDED LOSSES ARRAY', this.recordedLosses)
      },
      searchList (question) {
        if (
          this.answerList.some(
            answer =>
              answer.id === question.dependentRule.conditions[0].qid &&
              answer.answer === question.dependentRule.conditions[0].values[0]
          )
        ) {
          return true
        }
        return false
      },
      setCustomDiscountAnswer (jCode, val, id) {
        console.log('setting custom discount answer', jCode, val, id)
        if (val === 'YES') {
          this.customDiscountAnswers = [
            { jCode: jCode[0], answer: 1, id: id },
            { jCode: jCode[1], answer: 'DE', id: id },
            { jCode: jCode[2], answer: 'DEADB', id: id }
          ]
          console.log(this.customDiscountAnswers)
        } else {
          this.customDiscountAnswers = [
            { jCode: jCode[0], answer: 0, id: id },
            { jCode: jCode[1], answer: 'NO', id: id },
            { jCode: jCode[2], answer: 'NONE', id: id }
          ]
          console.log(this.customDiscountAnswers)
        }
        console.log(this.bldgProtection)
      },
      setDiscountAnswer (jCode, val, id) {
        console.log('setting discount answer', jCode, val, id)
        if (
          this.discountAnswers.length > 0 &&
          this.discountAnswers.find(answer => answer.id === id)
        ) {
          this.discountAnswers = this.discountAnswers.filter(
            answer => answer.id !== id
          )
          this.discountAnswers.push({ jCode: jCode, answer: val, id: id })
        } else {
          this.discountAnswers.push({ jCode: jCode, answer: val, id: id })
        }
        console.log(this.discountAnswers)
      },
      setAnswer (code, val, id) {
        console.log('setting answer')
        if (this.answerList.some(answer => answer.id === id)) {
          // replace answer if it already exists
          this.answerList = this.answerList.filter(answer => answer.id !== id)
          this.answerList.push({ qCode: code, answer: val.toString(), id: id })
        } else {
          this.answerList.push({ qCode: code, answer: val.toString(), id: id })
        }
        console.log(this.answerList)
      },
      closeCoverageIncreaseDialog () {
        this.coverageIncreaseDialog = false
        this.newCoverageAmount = ''
        this.$store.dispatch('clearCustomRentalQuote')
      },
      submitCoverageIncrease () {
        this.$store.dispatch('setRentalQuote', this.customQuote)
        this.$store.dispatch('clearCustomRentalQuote')
        this.coverageIncreaseDialog = false
      },
      async next () {
        let complete = await this.$refs.observer.validate()
        let valid = await this.$refs.form.validate()
        if (!valid || !complete) {
          return
        }
        this.$store.dispatch('setRenterSSN', this.formattedSSN)
        this.$store.dispatch('setRentalAnswers', this.questionAnswers)
        this.$store.dispatch('setRentalBldgProtection', this.bldgProtection)
        this.$store.dispatch('setRentalLosses', this.recordedLosses)
        this.$store.dispatch('setRentalConstruction', this.construction)
        const { renter } = this

        if (this.recordedLosses.length === 0) {
          console.log('calling with survey answers')
        }

        if (this.recordedLosses.length > 0) {
          console.log('calling with losses')
        }

        await this.$store.dispatch('getRentersQuoteWithAnswers', {
          deductible: 500,
          amt: 25000,
          renter,
          bldgProtection: this.bldgProtection,
          questionAnswers: this.questionAnswers,
          construction: this.construction,
          maritalStatus: this.maritalStatus,
          startDate: this.policy.startDate,
        })
        if (this.error) {
          console.log('error getting quote')
          return
        }
        this.$router.push({ path: '/renters/coverages' })
      },
      clickedField (value, dataPoint) {
        let userIdHere = 'Visitor'
        if (this.$store.getters.getuser && this.$store.getters.getuser.id) {
          userIdHere = this.$store.getters.getuser.id
        }
        this.$gtag.event('Renters Survey v1', {
          userId: userIdHere,
          dataPoint,
          action: 'Clicked field',
          data: `${value}`
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005f6c;
  color: white;
}

::v-deep .v-input--selection-controls.v-input .v-label {
    font-weight: bold;
    margin-bottom: 0;
    color: black;
}
</style>
